<template>
    <section>
        <h3>Benvenuto nel Pannello Amministratore di Smart 378</h3>
    </section>
</template>

<script>
import { Requests } from '@/api/requests.js'

export default {

    created() {
        this.getAccountList();

    },

    methods: {

        async getAccountList() {
            try {
                Requests.getAccountList();
            } catch {
                console.log(err);
            }
        }

    }

}

</script>
